.App {
  width: 100vw;
  text-align: center;
  color: white;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

.App-header {
  position: fixed;
  height: 10vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #34384D;
  z-index: 1;
}

.logoImage {
  position: fixed;
  height: 8vh;
  left: 10vw;
  top: 1vh;
}

.buttonConnect {
  background-image: url(./image/wallet.png);
  background-color: transparent;
  background-size: 100% 100%;
  color: #f6f6f7;
  text-align: center;
  position: fixed;
  height: 5vh;
  width: 120px;
  right: 10vw;
  top: 2.5vh;
  padding-left: 1.8rem;
  border: none;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


.App-body {
  position: absolute;
  top: 10vh;
  height: 90vh;
  width: 100vw;
  background-color: #262A3D;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}

.bodySec1 {
  margin-top: 1vh;
  background-color: #34384D;
  border-radius: 10px;
  width: 90vw;
  max-width: 700px;
  height: 80vh;
}

.bodySec1_Banner {
  width: 80vw;
  height: 30vh;
}

.bodySec1_textSection {
  text-align: left;
  margin-left: 10vw;
  font-size: 18px;
}

.bodySec2 {
  margin-top: 1vh;
  background-color: #34384D;
  border-radius: 10px;
  width: 90vw;
  max-width: 700px;
}

.bodySec2_textSection {
  text-align: left;
  margin-left: 10vw;
  font-size: 18px;
}

.PlusButton,
.MinusButton,
.NumberInNumberJS {
  margin-top: 2vh;
  flex: 1;
  font-size: larger;
}

.IDOJoinButton {
  border-radius: 20px;
  width: 40vw;
  height: 40px;
  background-color: #DE3897;
  color: white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  border: none;
  margin: 8px;
}

.bodySec3 {
  margin-top: 1vh;
  background-color: #34384D;
  border-radius: 10px;
  height: 20vh;
  width: 90vw;
  max-width: 700px;
}
.bodySec4 {
  margin-top: 1vh;
  background-color: #34384D;
  border-radius: 10px;
  height: 20vh;
  width: 90vw;
  max-width: 700px;
}
.bodySec5 {
  margin-top: 1vh;
  background-color: #34384D;
  border-radius: 10px;
  height: 20vh;
  width: 90vw;
  max-width: 700px;
}